const ENVIRONMENT_TYPES = {
    DEV: "DEV",
    TEST: "TEST",
    DEMO: "DEMO",
    PROD: "PROD"
};

const RESULT_CHANNEL_TYPES = {
    TIMING: 0,
    GAME: 1,
    JUDGEMENT: 2
};

const LOG_LEVEL_TYPES = {
    ERROR: "error",
    WARN: "warn",
    INFO: "info",
    VERBOSE: "verbose",
    DEBUG: "debug",
    SILLY: "silly"
};

const COMMON_SCHEDULE_STATUS_CODES = {
    PLANNED: "Event-1-",
    SCHEDULED: "Event-2-",
    GETTING_READY: "Event-3-",
    COMING_UP: "Event-50-",
    FINAL_JUDGE_CHECK: "Event-51-",
    RUNNING: "Event-4-",
    BREAK: "Event-5-",
    UNOFFICIAL: "Event-6-",
    OFFICIAL: "Event-7-",
    FINISHED: "Event-8-",
    DELAYED: "Event-9-",
    CANCELLED: "Event-11-",
    PROTESTED: "Event-12-",
    POSTPONED: "Event-13-",
    RESCHEDULED: "Event-14-",
    INTERRUPTED: "Event-15-"
};

const COMMON_RESULT_STATUS_CODES = {
    LIVE: "ResultStatus-2-",
    OFFICIAL: "ResultStatus-7-",
    UNOFFICIAL: "ResultStatus-6-"
};

const COMMON_PHASE_CODES = {
    CEREMONIES: "VICT",
    QUALIFICATION: "QUAL",
    PRELIMINARY: "PRE",
    ROUND_OF_32: "R32",
    ROUND_OF_16: "8F",
    QUARTER_FINALS: "QF",
    SEMI_FINALS: "SF",
    FINALS: "F"
};

const ATHLETE_IMAGE_EXTENSIONS = {
    PNG: "png",
    JPG: "jpg"
};

const SPORTS = {
    ALPINE: { name: "Alpine", sportCode: "alp" },
    ATHLETICS: { name: "Athletics", sportCode: "ath" },
    BADMINTON: { name: "Badminton", sportCode: "bdm" },
    BASKETBALL: { name: "Basketball", sportCode: "bkb" },
    BOBSLEIGH: { name: "Bobsleigh", sportCode: "bob" },
    BOXING: { name: "Boxing", sportCode: "box" },
    CROSS_COUNTRY: { name: "Cross Country", sportCode: "ccs" },
    CYCLING_ROAD: { name: "Cycling Road", sportCode: "crd" },
    CANOE_FLATWATER: { name: "Canoe Flatwater", sportCode: "csp" },
    CYCLING_TRACK: { name: "Cycling Track", sportCode: "ctr" },
    DIVING: { name: "Diving", sportCode: "div" },
    HIGH_DIVING: { name: "High Diving", sportCode: "hdv" },
    FIGURE_SKATING: { name: "Figure Skating", sportCode: "fsk" },
    GYMNASTICS: { name: "Gymnastics Artistic", sportCode: "gar" },
    GOLF: { name: "Golf", sportCode: "glf" },
    HANDBALL: { name: "Handball", sportCode: "hbl" },
    HOCKEY: { name: "Hockey", sportCode: "hoc" },
    LAWN_BOWLS: { name: "Lawn Bowls", sportCode: "lbo" },
    LIVE_SAVING: { name: "Live Saving", sportCode: "lfc" },
    LUGE: { name: "Luge", sportCode: "lug" },
    NETBALL: { name: "Netball", sportCode: "nbl" },
    NORDIC_COMBINED: { name: "Nordic Combined", sportCode: "ncb" },
    MARATHON_SWIMMING: { name: "Marathon Swimming", sportCode: "ows" },
    ROLLER_SKATING_SPEED: { name: "Roller Skating Speed", sportCode: "rol" },
    ROWING: { name: "Rowing", sportCode: "row" },
    RUGBY: { name: "Rugby", sportCode: "rug" },
    SHOOTING: { name: "Shooting", sportCode: "sho" },
    SKI_JUMPING: { name: "Ski Jumping", sportCode: "sjp" },
    SKELETON: { name: "Skeleton", sportCode: "skn" },
    SQUASH: { name: "Squash", sportCode: "squ" },
    SHORT_TRACK: { name: "Short Track", sportCode: "stk" },
    ARTISTIC_SWIMMING: { name: "Artistic Swimming", sportCode: "swa" },
    SWIMMING: { name: "Swimming", sportCode: "swm" },
    TRIATHLON: { name: "Triathlon", sportCode: "tri" },
    TABLE_TENNIS: { name: "Table Tennis", sportCode: "tte" },
    BEACH_VOLLEYBALL: { name: "Beach Volleyball", sportCode: "vbv" },
    WEIGHLIFTING: { name: "Weightlifting", sportCode: "wlf" },
    WATERPOLO: { name: "Waterpolo", sportCode: "wpo" },
    WRESTLING: { name: "Wrestling", sportCode: "wre" }
};

module.exports = {
    ENVIRONMENT_TYPES,
    RESULT_CHANNEL_TYPES,
    COMMON_PHASE_CODES,
    LOG_LEVEL_TYPES,
    COMMON_SCHEDULE_STATUS_CODES,
    COMMON_RESULT_STATUS_CODES,
    ATHLETE_IMAGE_EXTENSIONS,
    SPORTS
};
